import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });
const awsRegion = process.env.REACT_APP_AWS_REGION;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: userPoolWebClientId,
});

const sanitizeEmail = (input: string) => input.replace(/[^a-zA-Z0-9@.]/g, "");

const validatePassword = (password: string) => {
  if (/\s/.test(password)) {
    throw new Error("Password should not contain spaces");
  }
  return password;
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // in milliseconds

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const refreshAWSCredentials = (idToken: string, retries = MAX_RETRIES): Promise<void> => {
  return new Promise((resolve, reject) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins: {
        [`cognito-idp.${awsRegion}.amazonaws.com/${userPoolId}`]: idToken,
      },
    });

    AWS.config.credentials.refresh(async (error) => {
      if (error) {
        if (retries > 0) {
          console.log(`Retrying refreshAWS credentials... (${MAX_RETRIES - retries + 1})`);
          await delay(RETRY_DELAY);
          return refreshAWSCredentials(idToken, retries - 1)
            .then(resolve)
            .catch(reject);
        } else {
          console.log("Error refreshing AWS credentials:", error);
          reject(error);
        }
      } else {
        console.log("AWS credentials refreshed successfully");
        resolve();
      }
    });
  });
};

const clearCachedCredentials = () => {
  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
  }
  AWS.config.credentials = null;
};

export const signIn = (email: string, password: string, newPassword?: string | null): Promise<string> => {
  const sanitizedEmail = sanitizeEmail(email);
  const validatedPassword = validatePassword(password);

  const user = new CognitoUser({ Username: sanitizedEmail, Pool: userPool });
  const authDetails = new AuthenticationDetails({ Username: sanitizedEmail, Password: validatedPassword });

  return new Promise((resolve, reject) => {
    // Clear any existing credentials
    clearCachedCredentials();

    user.authenticateUser(authDetails, {
      onSuccess: async (result) => {
        console.log("Authentication successful:", result);
        try {
          await refreshAWSCredentials(result.getIdToken().getJwtToken());
          resolve(result.getAccessToken().getJwtToken());
        } catch (error) {
          console.log("Error after successful authentication:", error);
          reject(error);
        }
      },
      onFailure: (err) => {
        console.log("Authentication failed:", err);
        reject(err);
      },
      newPasswordRequired: (userAttributes) => {
        if (newPassword) {
          const mutableAttributes = Object.keys(userAttributes).reduce((acc, key) => {
            if (key !== "email" && key !== "email_verified") {
              acc[key] = userAttributes[key];
            }
            return acc;
          }, {});

          user.completeNewPasswordChallenge(newPassword, mutableAttributes, {
            onSuccess: async (result) => {
              console.log("New password set successfully:", result);
              try {
                await refreshAWSCredentials(result.getIdToken().getJwtToken());
                resolve(result.getAccessToken().getJwtToken());
              } catch (error) {
                console.log("Error after setting new password:", error);
                reject(error);
              }
            },
            onFailure: (err) => {
              console.log("Failed to set new password:", err);
              reject(err);
            },
          });
        } else {
          reject(new Error("New password is required."));
        }
      },
    });
  });
};

export const isAuthenticated = (): Promise<boolean> => {
  const user = userPool.getCurrentUser();
  if (user) {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          console.error("Error getting session:", err);
          reject(false);
        } else {
          resolve(session.isValid());
        }
      });
    });
  }
  return Promise.resolve(false);
};

export const getCurrentUser = (): CognitoUser | null => {
  return userPool.getCurrentUser();
};

export const getUserAttributes = (): Promise<{ [key: string]: string }> => {
  const user = getCurrentUser();
  return new Promise((resolve, reject) => {
    if (user) {
      user.getSession((err) => {
        if (err) {
          reject(err);
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              const userAttributes: { [key: string]: string } = {};
              attributes.forEach((attr) => {
                userAttributes[attr.getName()] = attr.getValue();
              });
              resolve(userAttributes);
            }
          });
        }
      });
    } else {
      reject("No user is currently logged in.");
    }
  });
};

export const signOut = (): void => {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  clearCachedCredentials();
};

export const forgotPassword = (email: string): Promise<void> => {
  const sanitizedEmail = sanitizeEmail(email);
  const user = new CognitoUser({ Username: sanitizedEmail, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const confirmPassword = (email: string, verificationCode: string, newPassword: string): Promise<void> => {
  const sanitizedEmail = sanitizeEmail(email);
  const user = new CognitoUser({ Username: sanitizedEmail, Pool: userPool });

  return new Promise((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};
